import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { LayoutComponent } from './layout/layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { MatModule } from '../shared/modules/mat-module';

@NgModule({
  declarations: [
    HeaderComponent,
    SideBarComponent,
    LayoutComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    MatModule
  ],
  exports: [HeaderComponent, SideBarComponent, LayoutComponent, LoginComponent, MatModule]
})
export class CoreModule { }
