import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private router: Router) {}
  isAuthenticated(){
    return !!localStorage.getItem('sessionToken');
  }
  canActivate(): boolean {
    // Check if the user is authenticated
    if (this.isAuthenticated()) {
      return true;
    }

    // If not authenticated, redirect to login and return false
    this.router.navigate(['/login']);
    return false;
  }
}
