import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient) { }
 
  /**
   * Lists year detail
   * @returns year detail 
   */
  public listYearDetail(): Observable<any>{ 
    return this.http.get(`${environment.API_BASE_URL}admin/dashboard-year-list`);
   }
   
   /**
    * Gets montly report
    * @returns montly report 
    */
   public getMontlyReport(user_year:number,booking_type:string): Observable<any>{
    const params: any = {};
    if (user_year !== undefined) {
        params.user_year = user_year;
    }
    if (booking_type !== undefined) {
        params.booking_type = booking_type;
    }
    // Construct the query string
    const queryString = new URLSearchParams(params).toString();
    return this.http.get(`${environment.API_BASE_URL}admin/booking-report?${queryString}`)
        .pipe(
            catchError(error => {
                console.error('Error fetching monthly report:', error);
                return throwError('Failed to fetch report, please try again later.');
            })
        );
   }
}
