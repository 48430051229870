import { Component, ElementRef, ViewChild } from '@angular/core';

import ApexCharts from 'apexcharts';
import { DashboardService } from './services/dashboard.service';
import { initDropdowns } from 'flowbite';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
options: any;
 bookingOptions:any;
 monthlyReportData:any;
 yearDetailData:any;
 years: number[]=[];
 userYear!:number;
 bookingType!:string;
 currentYear!: number;
 currentBookingType: string = 'all';
 constructor(private dashboard:DashboardService,private elementRef: ElementRef){}

  ngOnInit(): void {
  
    this.getMontlyReport(this.userYear,this.currentBookingType);
    this.listYearDetail();
    this.generateYears();
    initDropdowns();
  }

  /**
   * Generates years
   */
  generateYears() {
    this. currentYear = new Date().getFullYear();
    for (let i = this.currentYear; i >= this.currentYear - 10; i--) {
      this.years.push(i);
    }
  }
/**
 * Gets montly report
 * @param id 
 */
 getMontlyReport(userYear:number,bookingType:string) {
  this.dashboard.getMontlyReport(userYear,bookingType).subscribe({
    next: (response: any) => {
      this.monthlyReportData = response.data;
      const userMonthlyReport = response.data.usersMonthlyReport; 
      // Extract month names and counts from activeUserCountsByMonth
      const activeUser = userMonthlyReport.map((report:any) => report.activeUsers); 
      const inactiveUser = userMonthlyReport.map((report:any) => report.inactiveUsers);
        const userMonthName = userMonthlyReport.map((report: any) => report.monthName); 

      // Update the series data for active user counts
      this.options = {
        colors: ["#C55A11","#31C48D"],
        series: [
          {
            name: "Active Users",
            color: "#C55A11",
            type: "bar",
            data: activeUser.map((count: number, index: number) => ({ x: userMonthName[index], y: count }))
          },
          {
            name: "Inactive Users",
            color: "#1b3faa",
            type: "bar",
            data: inactiveUser.map((count: number, index: number) => ({ x: userMonthName[index], y: count }))
          }
        ],
        chart: {
          height: "350px",
          type: "bar",
          fontFamily: "Inter, sans-serif",
          toolbar: { show: false },
          zoom: { enabled: false }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            borderRadiusApplication: "end",
            borderRadius: 8
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: { fontFamily: "Inter, sans-serif" }
        },
        stroke: {
          show: true,
          width: 0,
          colors: ["transparent"]
        },
        grid: {
          show: false,
          strokeDashArray: 4,
          padding: { left: 2, right: 2, top: -14 }
        },
        dataLabels: { enabled: true },
        legend: { show: true },
        xaxis: {
          floating: false,
          labels: {
            show: true,
            style: { fontFamily: "Inter, sans-serif", cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400' }
          }
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
        yaxis: { show: true,
          labels: {
            formatter: function (value:number) {
              return + value;;
            }
          },
          
         },
        fill: { opacity: 1 }
      };

      // Render the user chart
      if (document.getElementById("user-chart") && typeof ApexCharts !== 'undefined') {
        const chart = new ApexCharts(this.elementRef.nativeElement.querySelector('#user-chart'), this.options);
        chart.render();
        chart.updateOptions(this.years);
      }
      // Handle booking data
      const bookingMonthlyReport = response.data.bookingsMonthlyReport; // Changed to bookingCountsByMonth
      // Extract month names and counts from activeUserCountsByMonth
      const completedFlightBookings = bookingMonthlyReport.filter((report:any) => report.completedFlightBookings); // Get month part (MM)   
      const completedHotelBookings = bookingMonthlyReport.filter((report:any) => report.completedHotelBookings);
      const completedTaxiBookings = bookingMonthlyReport.filter((report:any) => report.completedTaxiBookings);
      const monthNames = bookingMonthlyReport.map((report: any) => report.monthName); 
      this.bookingOptions = {
        tooltip: {
          enabled: true,
          x: { show: true },
          y: { show: true }
        },
        grid: {
          show: false,
          strokeDashArray: 4,
          padding: { left: 2, right: 2, top: -14 }
        },
        chart: {
          height: "365px",
          maxWidth: "100%",
          type: "area",
          fontFamily: "Inter, sans-serif",
          dropShadow: { enabled: false },
          toolbar: { show: false },
          zoom: { enabled: false }
        },
        legend: { show: true },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.55,
            opacityTo: 0,
            shade: "#1C64F2",
            gradientToColors: ["#1C64F2"]
          }
        },
        dataLabels: { enabled: false },
        stroke: { width: 6,
          dropShadow: {
            enabled: false, // Ensure this is set to false
            top: 0,
            left: 0,
            opacity: 0,
            blur: 0,
            color: '#000',
          }
         },
        xaxis: {
          floating: false,
          labels: {  show: true,
            style: { fontFamily: "Inter, sans-serif", cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400' }},
          axisBorder: { show: false },
          axisTicks: { show: false }
        },
        yaxis: { show: true,
          labels: {
            formatter: function (value:number) {
              return + value;
            }
          },
        
         },
      };

      const series = [];
      const colors = [];
    
      if (bookingType === 'flight') {
        if (completedFlightBookings.length > 0) {
          series.push({
            name: "Total Flight Booking",
            data: completedFlightBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedFlightBookings
            })),
            color: "#1b3faa"
          });
          colors.push("#1b3faa");
        }
      } else if (bookingType === 'hotel') {
        if (completedHotelBookings.length > 0) {
          series.push({
            name: "Total Hotel Bookings",
            data: completedHotelBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedHotelBookings
            })),
            color: "#31C48D"
          });
          colors.push("#31C48D");
        }
      } else if (bookingType === 'taxi') {
        if (completedTaxiBookings.length > 0) {
          series.push({
            name: "Total Taxi Booking",
            data: completedTaxiBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedTaxiBookings
            })),
            color: "#C55A11"
          });
          colors.push("#C55A11");
        }
      } else if (bookingType === 'all') {
        // Add all series if 'all' is selected
        if (completedFlightBookings.length > 0) {
          series.push({
            name: "Total Flight Booking",
            data: completedFlightBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedFlightBookings
            })),
            color:"#1b3faa"
          });
          colors.push("#1b3faa");
        }
        if (completedHotelBookings.length > 0) {
          series.push({
            name: "Total Hotel Bookings",
            data: completedHotelBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedHotelBookings
            })),
            color: "#31C48D"
          });
          colors.push("#31C48D");
        }
        if (completedTaxiBookings.length > 0) {
          series.push({
            name: "Total Taxi Booking",
            data: completedTaxiBookings.map((count: any) => ({
              x: count.monthName,
              y: count.completedTaxiBookings
            })),
            color: "#C55A11"
          });
          colors.push("#C55A11");
        }
      }

   // Update bookingOptions with the filtered series and colors
    this.bookingOptions = {
      ...this.bookingOptions, // Keep other options intact
      colors: colors,
      series: series
    };
      // Render the booking chart
      if (document.getElementById("booking-chart") && typeof ApexCharts !== 'undefined') {
        const bookingChart = new ApexCharts(this.elementRef.nativeElement.querySelector('#booking-chart'), this.bookingOptions);
        bookingChart.render();
        bookingChart.updateOptions(this.years);
      }
    },
    error: (error: any) => {
      console.error('Get monthly report error:', error.error?.message);
    }
  });
}

/**
 * Lists year detail
 */
public listYearDetail() {
  this.dashboard.listYearDetail().subscribe({
    next: (respone: any) => {
      this.yearDetailData = respone.data;      
      },
    error: (error: any) => {
      console.error('get monthly report error', error.error.message);
    }
  })
}

/**
 * Determines whether year select on
 * @param selectedYear 
 */
onSelectChart(selectedYear: number,selectedBookingType:string, chartType: 'user' | 'booking'): void {
  if (chartType === 'user') {
    this.currentYear = selectedYear;   
  } 
  else if (chartType === 'booking') {
    this.currentBookingType = selectedBookingType;
   
  }
  // Fetch data based on the updated years
  this.getMontlyReport(this.currentYear, this.currentBookingType);
}
}
