import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { initDropdowns, initFlowbite } from 'flowbite';

import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent  {
  title = 'travel-hero-admin';
  constructor(private router:Router){}
  ngOnInit(): void {
    initFlowbite();
  }
  
}

