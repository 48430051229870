import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(readonly http: HttpClient) {}
  baseUrl = environment.API_BASE_URL;
  /**
   * Sends a POST request to the server to authenticate a user.
   *
   * @param {Object} data - An object containing the user's email and password.
   * @param {string} data.email - The user's email.
   * @param {string} data.password - The user's password.
   * @return {Observable<any>} - An observable that emits the response from the server.
   */
  login(data: { email: string; password: string }): Observable<any> {
    return this.http.post(`${this.baseUrl}admin/login`, data);
  }
}
