import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { SocketService } from 'src/app/shared/services/socket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  apiLoader = false;
  loginForm: FormGroup;
  isPasswordVisible:boolean=false;
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService, private snackbar: ToasterService,private socketService:SocketService) {
    // Initialized the form in the constructor
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      password: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    localStorage.removeItem('sessionToken');
  }
  /**
   * Handles the login functionality by validating the login form and 
   * redirecting the user to the booking page upon successful login.
   *
   * @return {void} No return value
   */
  login(): void {
    if (this.loginForm.invalid) {
      this.snackbar.showToast('Please enter required fields', 'error', 3000);
      return;
    }
    this.apiLoader = true;
    this.loginService.login(this.loginForm.value).subscribe({
      next: (res) => {
         this.apiLoader = false;
        this.snackbar.showToast(res.message, 'success', 3000);
        localStorage.setItem('admin_login_id', res.data.id);
        this.socketService.connect();
        localStorage.setItem('admin_email', res.data.email);
        localStorage.setItem('sessionToken', res.data.session_token);
        this.router.navigate(['/dashboard']);
      },
      error: (err): void => {
        this.apiLoader = false;
        this.snackbar.showToast(err || 'Login Failed', 'error', 3000);
      }
    })
  }
    /**
   * Toggles password visibility
   */
    public togglePasswordVisibility()
    {
      this.isPasswordVisible = !this.isPasswordVisible;
   }
}
