<main class="p-0 sm:p-10 h-auto pt-4 w-full md:pl-4">

  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 pb-6">
    <div class="border rounded-lg dark:border-gray-600 place-content-around p-5">
      <h2 class="font-bold   items-center  uppercase text-primary-700">No Of Booking&nbsp; </h2>
      <p class="font-bold text-sm titlecase text-gray-600 mt-2">Total Booking:
        {{monthlyReportData?.booking_report?.completed_bookings}}</p>
    </div>
    <div class="border rounded-lg dark:border-gray-600 place-content-around p-5">
      <h2 class="font-bold   items-center  uppercase text-primary-700">No Of Users&nbsp; </h2>
      <p class="font-bold text-sm titlecase text-gray-600 mt-2">Total Users:
        {{monthlyReportData?.booking_report?.active_user_count +
        monthlyReportData?.booking_report?.inactive_user_count}}</p>
    </div>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-4">
    <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div class="grid grid-cols-2">
        <dl class="flex items-center">
          <dd class="text-gray-900 text-xl dark:text-white font-semibold mb-2 ">Users</dd>
        </dl>
      </div>

      <button id="dropdownDividerButton" data-dropdown-toggle="dropdownDivider"
        class="px-3 py-2 inline-flex items-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        type="button">  {{ currentYear || 'Year' }} 
       <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </button>
    <!-- Dropdown menu -->
      <div id="dropdownDivider"
        class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-28 dark:bg-gray-700 dark:divide-gray-600">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
          @for(year of yearDetailData?.userYearList;track year){
          <li>
            <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              (click)="onSelectChart(year,'', 'user');$event.preventDefault()">{{ year }}</a>
          </li>
          }
        </ul>
      </div>
      <div class="grid grid-cols-2">
      </div>
      <div id="user-chart"></div>

    </div>
    <!--bookings-->
    <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div class="grid grid-cols-2">
        <dl class="flex items-center">
          <dd class="text-gray-900 text-xl dark:text-white font-semibold mb-2 ">Bookings</dd>
        </dl>
      </div>
      <div class="flex justify-between mb-5">
        <div>
          <button id="dropdownDefaultButton" data-dropdown-toggle="lastDaysdropdown" data-dropdown-placement="bottom"
            type="button"
            class="px-3 capitalize py-2 inline-flex items-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            {{ currentBookingType }} 
             <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <div id="lastDaysdropdown" 
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-auto dark:bg-gray-700">
            <ul class="py-2 text-xs sm:text-sm text-gray-700 dark:text-gray-200 whitespace-nowrap" aria-labelledby="dropdownDividerButton">
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white "
                  (click)="onSelectChart(yearDetailData?.bookingYearList[0], 'all', 'booking'); $event.preventDefault()">All Bookings</a>
              </li>
            
              @if(yearDetailData?.bookingYearList && yearDetailData?.bookingYearList.length > 0) {
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    (click)="onSelectChart(yearDetailData?.bookingYearList[0], 'flight', 'booking'); $event.preventDefault()">Flight Bookings</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    (click)="onSelectChart(yearDetailData?.bookingYearList[0], 'hotel', 'booking'); $event.preventDefault()">Hotel Bookings</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white "
                    (click)="onSelectChart(yearDetailData?.bookingYearList[0], 'taxi', 'booking'); $event.preventDefault()">Taxi Bookings</a>
                </li>
              }
            </ul>
            
          </div>
        </div>
      </div>

      <div id="booking-chart"></div>

    </div>
  </div>

</main>