import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { DashboardComponent } from './core/pages/dashboard/dashboard.component';
import { LoginComponent } from './core/pages/auth/login/login.component';
import { AuthGuard } from './core/pages/auth/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./features/admin/pages/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'booking',
        loadChildren: () => import('./features/sales/pages/booking-management/booking-management.module').then(m => m.BookingManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'commision',
        loadChildren: () => import('./features/sales/pages/commision-management/commision-management.module').then(m => m.CommisionManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./features/sales/pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path:'sub-admin',
        loadChildren: () => import('./features/admin/sub-admin/sub-admin/sub-admin.module').then(m => m.SubAdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'payment',
        loadChildren: () => import('./features/sales/pages/payment-management/components/payment-management/payment-management.module').then(m => m.PaymentManagementModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
