<aside
  class="fixed top-0 left-0 z-40 w-64 sm:w-72 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
  aria-label="Sidenav" id="drawer-navigation">
  <div class="overflow-y-auto py-3 sm:py-5 px-2 sm:px-3 h-full bg-white dark:bg-gray-800">
      <ul class="space-y-2">
        @for (item of navigationData; track item.displayName) {
        <li>
          <a [routerLink]="[item.routerLink]" [class.active]="router.url.includes(item.routerLink)"
          class="flex items-center px-2 sm:px-3 py-3 sm:py-4 text-xs sm:text-sm font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group sidenav-nav-link">
          <i class="{{item.icon}}"></i>
          <span class="ml-3">{{item.displayName}}</span>
        </a>
        </li>
        }
      </ul>
  </div>