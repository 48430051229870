import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { SocketService } from 'src/app/shared/services/socket.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isDropdownOpen = false;
  adminEmail:string = localStorage.getItem('admin_email') || '';
  constructor(private router:Router,private socketService:SocketService){}
  ngOnInit(){
    initFlowbite();
    this.isDropdownOpen = false;
  }
  
    /**
     * Toggles dropdown
     * @param event 
     */
    toggleDropdown(event: MouseEvent) 
    {
      event.stopPropagation();
      this.isDropdownOpen = !this.isDropdownOpen;
    }
    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
      const target = event.target as HTMLElement;
      if (!target.closest('#user-menu-button') && !target.closest('#dropdown')) {
        this.isDropdownOpen = false;
      }
    }
    
    /**
     * Signs out
     */
    signOut() {
      this.router.navigate(['/login']);
      this.socketService.disconnect();
      localStorage.clear();
    }
}
