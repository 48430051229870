import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private router:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     const authToken = localStorage.getItem("sessionToken");
      if(authToken) {        
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`),
      });
    }
    
    return next.handle(request).pipe(
      retry(1),
      catchError((error:HttpErrorResponse)=>{
        let errorMessage='';
        console.log(' auth error',error);
        
        if(error.error instanceof ErrorEvent){
          //client side error
          errorMessage = `Error: ${error.error.message}`;
        }
        else{
          //server side error
          errorMessage=error.error.message;
            // Catch "401 Unauthorized" responses
            if (error.status === 401 ) {
               this.router.navigate(['login']);       
        }
        }
        return throwError(errorMessage);
      }), 
    );
  }

  }
