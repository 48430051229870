import { Component } from '@angular/core';
import  {navigationData} from './store/nav-data';
import { ISidebarItem } from './models/sidebar-item.interface';
import { initFlowbite } from 'flowbite';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {
  navigationData: ISidebarItem[] = navigationData;
  constructor(public router:Router){}
  ngOnInit(): void {
    initFlowbite();
  }
}
