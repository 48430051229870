import { ISidebarItem } from '../../../core/side-bar/models/sidebar-item.interface';

export const navigationData: ISidebarItem[] = [
    { displayName: 'Dashboard', icon: 'bx bxs-dashboard', routerLink: 'dashboard' },
    { displayName: 'Booking Management', icon: 'bx bxs-calendar', routerLink: 'booking' },
    { displayName: 'Commission Management', icon: 'bx bxs-dollar-circle', routerLink: 'commision' },
    { displayName: 'User Management', icon: 'bx bxs-user-detail', routerLink: 'user' },
    { displayName: 'Sub Admin Management', icon: 'bx bx-user-check', routerLink: 'sub-admin' },
    { displayName: 'Payment Management', icon: 'bx bxl-paypal', routerLink: 'payment' },
    { displayName: 'Settings', icon: 'bx bxs-cog', routerLink: 'settings' },
];  
